<template>
    <div class="body">
        <Header />
        <div role="main" class="main">
            <section class="page-header mb-0">
                <div class="container">
                    <div class="row align-items-center mt-5 pt-5">
                        <div class="col-md-8 text-left">
                            <span class="tob-sub-title text-color-primary d-block">Sign in / Sign up</span>
                            <h1 class="font-weight-bold">로그인/회원가입</h1>
                            <!-- <p class="lead">sub title</p> -->
                        </div>
                        <div class="col-md-4">
                            <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
                                <li><router-link to="/">Home</router-link></li>
                                <li class="active">Sign in / Sign up</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section">
                <div class="container">
                    <div class="row" v-show="!show_find_pw">
                        <div class="col-lg-6 mb-5 mb-lg-0">
                            <div class="bg-primary rounded p-5">
                                <h2 class="text-color-light font-weight-bold text-4 mb-4">로그인</h2>

                                <form id="frmSignIn">
                                    <div class="form-row">
                                        <div class="form-group col mb-2">
                                            <label class="text-color-light-2" for="frmSignInEmail">이메일</label>
                                            <input type="email" v-model="login_email" maxlength="100" class="form-control bg-light rounded border-0 text-1" name="email" id="frmSignInEmail" required>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col">
                                            <label class="text-color-light-2" for="frmSignInPassword">비밀번호</label>
                                            <input type="password" v-model="login_pw" class="form-control bg-light rounded border-0 text-1" name="password" id="frmSignInPassword" required>
                                        </div>
                                    </div>
                                    <div class="form-row mb-3">
                                        <div class="form-group col">
                                            <div class="form-check checkbox-custom checkbox-custom-transparent checkbox-default">
                                                <input class="form-check-input" type="checkbox" id="frmSignInRemember"
                                                v-model="auto_login">
                                                <label class="form-check-label text-color-light-2" for="frmSignInRemember">
                                                    자동 로그인
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col text-right">
                                            <a href="#" class="forgot-pw text-color-light-2 d-block" @click="show_find_pw = true">비밀번호를 잊으셨습니까?</a>
                                        </div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col text-right">
                                            <button type="submit" class="btn btn-dark btn-rounded btn-v-3 btn-h-3 font-weight-bold" @click="Login()">로그인</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="border rounded h-100 p-5">
                                <h2 class="font-weight-bold text-4 mb-4">회원가입</h2>

                                <form id="frmRegister">
                                    <div class="form-row">
                                        <div class="form-group col mb-2">
                                            <label for="frmRegisterEmail">이메일</label>
                                            <input type="email" v-model="signup_email" maxlength="100" class="form-control bg-light-5 rounded border-0 text-1" name="email" id="frmRegisterEmail" required>
                                        </div>
                                    </div>
                                    <div class="form-row mb-5">
                                        <div class="form-group col-lg-6">
                                            <label for="frmRegisterPassword">비밀번호</label>
                                            <input type="password" v-model="signup_pw1" class="form-control bg-light-5 rounded border-0 text-1" name="password" id="frmRegisterPassword" required>
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <label for="frmRegisterPassword2">비밀번호 확인</label>
                                            <input type="password" v-model="signup_pw2" class="form-control bg-light-5 rounded border-0 text-1" name="password2" id="frmRegisterPassword2" required>
                                        </div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col text-right">
                                            <button type="submit" class="btn btn-primary btn-rounded btn-v-3 btn-h-3 font-weight-bold" @click="Signup()">회원가입</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-show="show_find_pw">
                        <div class="col-lg-6">
                            <div class="border rounded h-100 p-5">
                                <h2 class="font-weight-bold text-4 mb-4">비밀번호 재발급</h2>

                                <form id="frmRegister">
                                    <div class="form-row">
                                        <div class="form-group col mb-2">
                                            <label for="frmRegisterEmail">이메일</label>
                                            <input type="email" v-model="findpw_email" maxlength="100" class="form-control bg-light-5 rounded border-0 text-1" name="email" id="frmRegisterEmail" required>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mt-4">
                                        <div class="col text-right">
                                            <button type="submit" class="btn btn-primary btn-rounded btn-v-3 btn-h-3 font-weight-bold" @click="FindPw()">비밀번호 재발급</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
    components:{
        Header, Footer
    },
    data(){
        return{
            show_find_pw: false,

            login_email: '',
            login_pw: '',
            auto_login: false,

            signup_email: '',
            signup_pw1: '',
            signup_pw2: '',

            findpw_email: '',
        }
    },
    methods:{
        Login(){
            this.$router.push('/')
        },
        
        Signup(){
            
        },

        FindPw(){
            this.show_find_pw = false
        }
    }
}
</script>

<style>

</style>