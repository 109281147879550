<template>
    <header id="header" class="position-fixed w-100 px-0">
        <div class="header-body">
            <!-- <div class="header-top bg-white">
                <div class="header-top-container container">
                    <div class="header-row">
                        <div class="header-column justify-content-start">
                            <span class="d-none d-sm-flex align-items-center">
                                <i class="fas fa-map-marker-alt mr-1"></i>경기도 하남시 미사대로520, CA동 804호
                            </span>
                            <span class="d-none d-sm-flex align-items-center ml-4">
                                <i class="fas fa-phone mr-1"></i>
                                <a href="tel:+1234567890">1533-4178</a>
                            </span>
                        </div>
                        <div class="header-column justify-content-end">
                            <ul class="nav">
                                <li v-if="!login">
                                    <ul class="d-flex p-0" style="list-style: none">
                                        <li class="nav-item">
                                            <router-link class="nav-link" to="/sign">Login</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link class="nav-link" to="/sign">Join Us</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="py-2" v-else>{{id}} 님</li>
                                <li class="nav-item">
                                    <a class="nav-link dropdown-menu-toggle py-2" id="dropdownLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                        English	<i class="fas fa-angle-down fa-sm"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownLanguage">
                                        <li><a class="no-skin"><img src="@/assets/img/blank.gif" class="flag flag-us" alt="English" /> English</a></li>
                                        <li><a class="no-skin"><img src="@/assets/img/blank.gif" class="flag flag-es" alt="Español" /> Español</a></li>
                                        <li><a class="no-skin"><img src="@/assets/img/blank.gif" class="flag flag-fr" alt="Française" /> Française</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="header-container container" style="min-height:66px !important;">
                <div class="header-row">
                    <div class="header-column justify-content-start">
                        <div class="header-logo">
                            <router-link to="/">
                                <img alt="logo" width="127" src="@/assets/img/logo.png">
                            </router-link>
                        </div>
                    </div>
                    <div class="header-column justify-content-center">
                        <div class="header-nav justify-content-lg-center">
                            <div class="header-nav-main header-nav-main-effect-1 header-nav-main-sub-effect-1 px-0">
                                <nav class="collapse">
                                    <ul class="nav flex-column flex-lg-row" id="mainNav">
                                        <li class="dropdown opened">
                                            <router-link to="/company" class="dropdown-item dropdown-toggle">
                                                COMPANY
                                            </router-link>
                                            <ul class="dropdown-menu d-block">
                                                <li>
                                                    <router-link to="/vision" class="dropdown-item dropdown-toggle">Vision</router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/core-value" class="dropdown-item dropdown-toggle">Core Values</router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/client" class="dropdown-item dropdown-toggle">Our Client</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown opened">
                                            <router-link to="/business" class="dropdown-item dropdown-toggle">
                                                BUSINESS
                                            </router-link>
                                            <ul class="dropdown-menu d-block">
                                                <li>
                                                    <router-link to="/business/payment" class="dropdown-item dropdown-toggle">전자결제</router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/business/myparking" class="dropdown-item dropdown-toggle">마이주차</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown opened">
                                            <router-link to="/news" class="dropdown-item dropdown-toggle">
                                                PR
                                            </router-link>
                                            <ul class="dropdown-menu d-block">
                                                <li>
                                                    <router-link to="/news" class="dropdown-item dropdown-toggle" >News</router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/corpo-disclosure" class="dropdown-item dropdown-toggle" >기업공시</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown opened">
                                            <router-link to="/contact-us" class="dropdown-item dropdown-toggle">
                                                CONTACT
                                            </router-link>
                                            <ul class="dropdown-menu d-block">
                                                <li>
                                                    <router-link to="/contact-us" class="dropdown-item dropdown-toggle" >문의하기</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="header-column justify-content-end">
                        <div class="d-none d-sm-flex">
                            <a href="pdf/[사업계획] 01. 사업계획서.pdf" target="_blank" class="btn btn-outline btn-primary bg-transparent border-0 btn-sticky-primary btn-4">
                                <span class="wrap">
                                    <span>Download Company Intro</span>
                                    <i class="fas fa-arrow-right ml-2"></i>
                                </span>
                            </a>
                        </div>
                        
                        <button class="header-btn-collapse-nav-light header-btn-collapse-nav ml-3" data-toggle="collapse" data-target=".header-nav-main nav">
                            <span class="hamburguer">
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
  
<script>
export default {
    data(){
        return{
            login:false,
            id:'Daesanace'
        }
    },
}
</script>
<style scoped>
.btn-light.btn-outline:hover, .btn-light.btn-outline:focus, html.sticky-header-active .btn-sticky-light.btn-outline:hover, html.sticky-header-active .btn-sticky-light.btn-outline:focus{
    color: #2388ed;
}
.color-logo{
    display: none;
}
.header-effect-shrink-active .light-logo{
    display: none;
}
.header-effect-shrink-active .color-logo{
    display: block;
}
#header .header-btn-collapse-nav.header-btn-collapse-nav-light .hamburguer span, #header .header-btn-collapse-nav.header-btn-collapse-nav-light .close span{
    background: #000;
}
</style>